@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Titillium+Web&display=swap');
body {
    font-family: 'Arial'; 
      color: white;
      background-color:#6b03ff;
    margin: 0px !important;
    padding: 0px !important;
    cursor: default;
    background: linear-gradient(90deg, #6b03ff, #1133ff);
      background-size: 400% 400%;
  
      -webkit-animation: Gradient 15s ease infinite;
      -moz-animation: Gradient 15s ease infinite;
      -o-animation: Gradient 15s ease infinite;
      animation: Gradient 15s ease infinite;
  }
  
  .MenuPage #top {
    display: flex;
    position: relative;
  }
  
  @-webkit-keyframes Gradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes Gradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-o-keyframes Gradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes Gradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }

  .fa-chevron-down {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateY(-50%);

    font-size: 50px;
    animation: arrow-bounce 2s infinite;
  }
  
  .MenuPage .ui-tabs{
              position:static;
          }
  
  /*nav a:hover {
      color:#5212aa;
      background-color:#FFFFFF;
  }*/
  
  .MenuPage .title {
          margin-top: 0px;
          font-weight: bold;    
          color:white;
          font-family: 'Pacifico';
          font-size:8vw;
          animation: bounce 5s infinite alternate;
          position: relative;
          left: 12vw;
          text-align: center;
    /*-webkit-animation: bounce 1s infinite alternate;*/
      }
  
  .MenuPage #top {
    min-height: 100vh;
  }
  
  .MenuPage #menu-2 {
    background-image: linear-gradient(-90deg,#43b1ff00,#1100ff 15%,#1100ff 85%,#43b1ff00);
    min-height: 430px;
    text-align: center;
    padding: 5px 10vw;
    
    display: grid;
  }
  .MenuPage #menu-2 p {
    font-family: 'Exo 2', 'sans-serif';
  }
  .MenuPage #menu-2 h1 {
    line-height: 1.58;
    font-weight: 500;
    font-family: 'Bree Serif';
    color:'white';
    font-size: 150%;   
    margin: 0px;
  }
  .MenuPage #menu-2 p {
      font-size: 100%;
  }
  .MenuPage #menu-2 h2 {
    font-size: 130%;
  }
  .MenuPage #menu-2 #left {
    float: left;
    text-align: left;
    display: inline-flex;
    padding: 30px 0px;
  }
  .MenuPage #menu-2 #left img {
    height: 10%;
    width: 10%;
  }
  .MenuPage #menu-2 #text {
    display: inline;
  }
  .MenuPage #menu-2 .about-me, #menu-2 .how-to-use {
    max-width: 80vw;
  }
  
  @media all and (min-width:820px) {
      .MenuPage #top {
          margin:0;
          background: linear-gradient(90deg, #6b03ff, #1133ff);
      background-size: 400% 400%;
  
      -webkit-animation: Gradient 15s ease infinite;
      -moz-animation: Gradient 15s ease infinite;
      -o-animation: Gradient 15s ease infinite;
      animation: Gradient 15s ease infinite;
      }
    
    .MenuPage .button {
      background-color: #7289DA; 
      border: none;
      color: white;
      text-align: center;
      text-decoration: none;
      font-size: 5vw;
      margin: 4px 2px;
      cursor: pointer;
  }
    
    .MenuPage #butoes button {
    border: none;
    padding: 1vw 3vw;
    font-size: 4vw;
    font-weight: bold;
    margin: 4px 2px;
    outline: none;
    border-radius: 12px;
  }
    
    .MenuPage #content {
      float:'left';
      min-width: 50%;
      text-align: 'center';
    }
    
    .MenuPage #direita {
      float:'right';
      position: 'relative';
      top: 180;
      right: 50;
      max-width:45%;
      height:100%;
    }
    
    .MenuPage .texto {
      margin-top: 0;
          color:white;
          text-align: center;
          animation: bounce1 5s infinite alternate;
      font-size:2vw;
      position: relative;
        top: 25vh;
    }
    
    .MenuPage .login {
      padding-right: 5px;
      cursor: pointer;
    }
    
    .MenuPage #gifsetup {
      float: right;
      width: 400;
      height: 127.3;
      max-height: 100%;
      max-width: 100%;
    }
  }
  
  /*celular*/
  @media all and (max-width:820px) {
    .MenuPage #top {
      flex-direction: column;
    }
    
    .MenuPage .button {
      background-color: #7289DA; 
      border: none;
      color: white;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 5vw;
      margin: 4px 2px;
      cursor: pointer;
  }
    
    button {
    border: none;
    padding: 1vw 3vw;
    background-color: #B0BEC5;
    font-size: 6vw;
    font-weight: bold;
    color: #555;
    margin: 4px 2px;
    outline: none;
    border-radius: 5px;
    }
      .MenuPage .texto {
        margin-top: 0;
          color:white;
          text-align: center;
          animation: bounce1 5s infinite alternate;
        position: relative;
        top: 25px;
        font-size: 20px;
      }
      
      .MenuPage .title {
        font-size:300%;
        text-align: center;
        left: 0vw;
      }
    
    .MenuPage #content {
      text-align:center
    }
    
    .MenuPage .modal-content {
      width: 100%;
    }
    
    .MenuPage #menu-2 {
    background-image: linear-gradient(-90deg,#43b1ff00,#1100ff 15%,#1100ff 85%,#43b1ff00);
    min-height: 600px;
  }

    .MenuPage #gifsetup {
      float: center;
      width: 400;
      height: 127.3;
      max-height: 100%;
      max-width: 100%;
    }
    .MenuPage #menu-2 #left {
      float: left;
      text-align: left;
      display: inline;
      padding: 30px 0px;
    }
  
    .MenuPage #menu-2 #left img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 30%;
      width: 30%;
    }
  }

 .MenuPage a:link, a:visited {
    color: white;
  }
  
  .MenuPage .about-me h1 {
    display: flex;
    justify-content: center;
    margin: 0px;
  }
  .MenuPage .how-to-use {
    padding: 20px 0px;
    position: relative;
    top: 75;
  }
  
  /*.shine {
    background: #fff -webkit-gradient(linear, left top, right top, from(#fff), to(#fff), color-stop(0.5, #fff)) 0 0 no-repeat;
	background-image: -webkit-linear-gradient(-40deg, transparent 0%, transparent 40%, #000000 50%, transparent 60%, transparent 100%);
	background-size: 200px;
	background-clip: text;
	animation-name: shine;
	animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
	text-shadow: 0 0px 0px #646464;
  }
  @keyframes shine {
    0%, 10% {
      background-position: -100000px;
    }
    20% {
      background-position: top left;
    }
    90% {
      background-position: top right;
    }
    100% {
      background-position: 100000px;
    }
}*/
  
  .MenuPage #twitter {
    height: 25px;
    width: 25px;
    position: relative;
    top: 5px;
  }
  
  .MenuPage #fusion {
    padding: 0em 20em;
  }
  
      .MenuPage .button:hover {
          font-size: 900%; 
      }
  
  .MenuPage #butoes {
    text-align: center;
       font-family: 'Titillium Web'; 
        position: relative;
  }
      .MenuPage .grow {
          background-color: #7289DA; 
      border: none;
      color: white;
        transition: 0.5s;
    transform: scale(1);
      }
    
  .MenuPage .grow:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }
  
  .MenuPage .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }
  
  .MenuPage .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  .MenuPage #caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    text-align: center;
    color: #ccc;
    padding: 10px 0;
    height: 150px;
  }
  
  .MenuPage .modal-content, #caption {
    animation-name: zoom;
    animation-duration: 0.6s;
  }
  
  @keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
  }
  
  .MenuPage .close {
    position: absolute;
    top: 35px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  .MenuPage .close:hover,
  .MenuPage .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
    
    @keyframes bounce {
    from {
      /*transform: translateY(0px);*/
      transform: rotate(3deg);
    }
    to {
      /*transform: translateY(-15px);*/
      transform: rotate(-3deg);
    }
  }
  
  @keyframes bounce1 {
    from {
      /*transform: translateY(0px);*/
      transform: rotate(1deg);
    }
    to {
      /*transform: translateY(-15px);*/
      transform: rotate(-1deg);
    }
  }
  
  @keyframes arrow-bounce{
    0%,20%,50%,60%,80%,100%{
      transform:translateY(10px)
    }
    40%{
      transform:translateY(-20px)
    }
    60%{
      transform:translateY(-3px)}
    }