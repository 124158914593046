.inline { 
    width: auto;
    height: auto;
    padding: .2em;
    margin: -.2em 0;
    border-radius: 3px;
    font-size: 85%;
    font-family: Consolas,Andale Mono WT,Andale Mono,Lucida Console,Lucida Sans Typewriter,DejaVu Sans Mono,Bitstream Vera Sans Mono,Liberation Mono,Nimbus Mono L,Monaco,Courier New,Courier,monospace;
    text-indent: 0;
    border: none;
    white-space: pre-wrap;
}

code {
    background-color: #2f3136;
    border-color: #202225;
    border-radius: 1px;
    font-size: 0.875rem;
    line-height: 1.125rem; 
    text-indent: 0;
    white-space: pre-wrap;
}

#div0001 {
    padding: 0px 5vh;
}