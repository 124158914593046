@import url('https://fonts.googleapis.com/css?family=Oswald');

  

.CoinsPage h1, h2 {
    padding-top: 10px;
    font-family: 'Lato', sans-serif;
    text-align: center;
}

.CoinsPage h2 {
    margin-top: 50px;
}

.CoinsPage body {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
}

.CoinsPage p {
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    margin: 0px;
	font-weight: 600;
}

.CoinsPage .botaoPagSeguro {
    margin: 20px;
    text-align: center;
}

.CoinsPage .purchaseButton {
    margin: 10px;
    background-color: #6730ff; /* Green */
  border-radius: 8px;
  border-style: solid;
  border-color: #6730ff;
  color: white;
  height: 50px;
  padding: 10px 60px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}

.purchaseButton {
    transition-duration: 0.4s;
  }
  
  .purchaseButton:hover {
    background-color: #6730ff;
    background: linear-gradient(90deg, #6b03ff, #1133ff);
    background-size: 300% 300%;

    -webkit-animation: Gradient 5s ease infinite;
    -moz-animation: Gradient 5s ease infinite;
    -o-animation: Gradient 5s ease infinite;
    animation: Gradient 5s ease infinite;
    color: white;
  }

  #stripe-logo {
    position: relative;
    margin-left: 2px;
    width: 40px;
    height: 15px;
    bottom: 1px;
    filter: invert(100%);
    vertical-align: middle;
  }

  @-webkit-keyframes Gradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes Gradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-o-keyframes Gradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes Gradient {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.CoinsPage li {
    text-align: left;
	font-weight: 600;
}

.CoinsPage a {
    font-weight: bold;
    color: honeydew;
}

.CoinsPage .main {
    margin: 40px 2vw;
    background-color: #222;
    border-radius: 10px;
    border-style: initial;
    border-color: #222;
}

.CoinsPage .main .text {
    margin: 5px 10%;
    background-color: #222;
    border-radius: 10px;
    border-style: initial;
    border-color: #222;
}

.CoinsPage .buySpace {
    display: flex;
      font-family: 'Exo 2', 'sans-serif';
      background-color: #222;
      padding: 10px;
      padding-bottom: 30px;
      border-radius: 6px;
      text-align: center;
      position: relative;
      max-height: fit-content;
      min-width: 300px;
      max-width: fit-content;
      top: 30px;
      margin: auto;
      margin-bottom: 100px;
}

/*celular*/
@media all and (max-width:820px) {
    .CoinsPage .buySpace {
        display: inline;
        background: none;
    }
  }

.CoinsPage .buySpace h2 {
    margin-top: 30px;
}

.CoinsPage .buySpace div {
    margin: 20px 50px;
}

.CoinsPage .highlight {
    background-color: rgb(20, 20, 20);
    border-radius: 5px;
    border-color: rgb(10, 10, 10);
    border-width: 2px;
    border-style:solid;
}