div .wiki {
    background-color: #222;
    width: 100vw;
    height: 100vh;
    color: white;
    display: flex;
}

.wiki .sidebar {
    position: relative;
    background-color:#9851ff;
    min-width: fit-content;
    width: 20%;
    max-width: 250px;
    height: 100vh;
    overflow-y: auto;
    transition: 0.3s linear;
    transition-property: left;
}

@media all and (min-width:820px) {
    .wiki .text {
        padding-left: 10px;
    }

    .wiki .sidebar {
        position: relative;
    }

    .wiki .close-btn {
        visibility: hidden;
    }
    
    .wiki .menu-btn {
        visibility: hidden;
    }
}

@media all and (max-width:820px) {
    .wiki .text {
        padding-left: 50px;
    }

    .wiki .sidebar {
        position: absolute;
        left: -28vh;
    }

    .wiki .close-btn {
        visibility: visible;
    }
    
    .wiki .menu-btn {
        visibility: visible;
    }
}

.wiki .sidebar.active {
    left: 0;
    transition: 0.3s linear;
    transition-property: left;
}

.wiki .sidebar .menu {
    width: 100%;
}

.wiki .sidebar .menu .item {
    position: relative;
    cursor: pointer;
    background-color:rgba(125, 68, 212, 1);
}

.wiki .sidebar .menu .item a {
    color: white;
    
    font-family: 'Exo 2', 'sans-serif';
    font-size: 16px;
    text-decoration: none;
    display: block;
    padding: 5px 20px;
    line-height: 30px;
}

.wiki .sidebar .menu .item a:hover {
    background-color: rgba(91, 47, 158, .4);
    transition: 0.3s ease;
}

.wiki .sidebar .menu .item a .dropdown {
    position: absolute;
    right: 0;
    margin: 8px 20px;
    transition: 0.3s ease;
}

.wiki .sidebar .menu .item .sub-btn {
    padding-right: 60px;
}

.wiki .sidebar .menu .item .sub-menu {
    background-color: rgba(255, 255, 255, 0.2);
    display: none;
}

.wiki .sidebar .menu .item .sub-menu a {
    padding-left: 35px;
}

.wiki .rotate {
    transform: rotate(90deg);
}

.wiki .close-btn {
    position: relative;
    color: white;
    font-size: 20px;
    margin: 10px;
    cursor: pointer;
}

.wiki .menu-btn {
    position: absolute;
    margin: 10px;
    font-size: 20px;
    cursor: pointer;
}