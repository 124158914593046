@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');
body {
    font-family: 'Arial'; 
      color: white;
      position: relative;
      background-color:#6b03ff;
    margin: 0px !important;
    padding: 0px !important;
  }
  
  .DashboardPage #top {
    display: flex;
  }
  .DashboardPage .main {
    position: relative;
    top: 50px;
  }
  
  @-webkit-keyframes Gradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-moz-keyframes Gradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @-o-keyframes Gradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  @keyframes Gradient {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
  }
  
  .DashboardPage .ui-tabs{
              position:static;
          }
  
  /*nav a:hover {
      color:#5212aa;
      background-color:#FFFFFF;
  }*/
  
  .DashboardPage .title {
          margin-top: 0px;
          font-weight: bold;    
          color:white;
          font-family: 'Pacifico';
          font-size:8vw;
          animation: bounce 5s infinite alternate;
          position: relative;
          left: 11vw;
          text-align: center;
    /*-webkit-animation: bounce 1s infinite alternate;*/
      }
  
  .DashboardPage #top {
    min-height: 100vh;
  }
  
  .DashboardPage #menu-2 {
    background-image: linear-gradient(-90deg,#43b1ff00,#1100ff 15%,#1100ff 85%,#43b1ff00);
    min-height: 430px;
  }
  .DashboardPage #menu-2 p {
    font-family: 'Exo 2', 'sans-serif';
  }
  .DashboardPage #menu-2 h1 {
    font-size: 150%;   
  }
  .DashboardPage #menu-2 p {
      font-size: 100%;
  }
  
  @media all and (min-width:820px) {
      .DashboardPage #top {
          margin:0;
          background: linear-gradient(90deg, #6b03ff, #1133ff);
      background-size: 400% 400%;
  
      -webkit-animation: Gradient 15s ease infinite;
      -moz-animation: Gradient 15s ease infinite;
      -o-animation: Gradient 15s ease infinite;
      animation: Gradient 15s ease infinite;
      }
    
    .DashboardPage #content {
      float:'left';
      min-width: 50%;
      text-align: 'center';
    }
    
    .DashboardPage #direita {
      float:'right';
      position: 'relative';
      top: 180;
      right: 50;
      max-width:45%;
      height:100%;
    }
    
    .DashboardPage .texto {
      margin-top: 0;
          color:white;
          text-align: center;
          animation: bounce1 5s infinite alternate;
      font-size:30px;
      position: relative;
        top: 25vh;
    }
    .DashboardPage .right {
      display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    align-items: center;
    justify-content: center;
    }
    .DashboardPage .guilds {
      background-color: #222;
      max-height: fit-content;
      min-width: 40vw;
      max-width: 40vw;
      padding: 10px;
      margin: 30px;
      border-radius: 6px;
    }
    
    .DashboardPage .login {
      padding-right: 5px;
    }
  }
  
  @media all and (max-width:820px) {
      nav {
          height:100%;
      text-align: center;
      }
  
      nav li {
          margin:0px;
      }
    
    .DashboardPage #top {
      flex-direction: column;
    }
    
    .DashboardPage .button {
      background-color: #7289DA; 
      border: none;
      color: white;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 5vw;
      margin: 4px 2px;
      cursor: pointer;
  }
    
    button {
    border: none;
    padding: 1vw 3vw;
    background-color: #B0BEC5;
    font-size: 6vw;
    font-weight: bold;
    color: #555;
    margin: 4px 2px;
    outline: none;
    border-radius: 5px;
    }
      .DashboardPage .texto {
        margin-top: 0;
          color:white;
          text-align: center;
          animation: bounce1 5s infinite alternate;
        position: relative;
        top: 25px;
        font-size: 20px;
      }
      
      .DashboardPage .title {
        font-size:300%;
        text-align: center;
        left: 0vw;
      }
  .DashboardPage .guilds {
    background-color: #222;
    max-height: fit-content;
    max-width: 80%;
    min-width: 80%;
    padding: 5px;
    margin: 30px 10% 30px 10%;
    border-radius: 6px;
  }
    
  }
  .DashboardPage .guilds a {
    padding-top: 2px;
  }
  .DashboardPage .guilds a:link, a:visited {
    text-decoration: none;
    color: white;
  }
  
  .DashboardPage .guilds a:hover, a:active {
    text-decoration: underline;
    color: white;
  }
  
    .DashboardPage #gear{
        filter: invert(100%);
        position: relative;
        top: 2px;
        margin-right: 3px;
        cursor: default;
    }
    .DashboardPage #icon {
      cursor: default;
    }

    .DashboardPage #discord{
      filter: invert(100%);
  }

  .DashboardPage .right-side-entries img {
    border-radius: 50%;
  }
    
    .DashboardPageLoading #loading {
  display: flex;
  position: initial;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: auto;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #6b03ff;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  justify-content: center;
  align-items: center;
  }

  #timeText {
    position: relative;
    top: 5vh;
    display:block;
    z-index: 1;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Exo 2";
  }

  .DashboardPageLoading {
    position: relative;
    top: 40vh;
    z-index: 1;
    margin: auto;
    justify-content: center;
    align-items: center;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }