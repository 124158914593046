.sidebar .menu {
    position: fixed;
    background-color:#9851ff;
    min-width: fit-content;
    width: 20vw;
    max-width: 250px;
    height: 100vh;
    overflow-y: auto;
    transition: 0.3s linear;
    transition-property: left;
    width: 100%;
  }

  /*celular*/
  @media all and (max-width:820px) {
    .sidebar .menu {
        left: -100%;
        transition: 0.3s linear;
        transition-property: left;
        z-index: 1;
    }
  
    .sidebar .menu .close-btn {
        visibility: visible;
    }
    
    .sidebar .menu-btn {
        visibility: visible;
    }

    .sidebar .menu.active {
        left: 0;
        top: 35px;
      }
  }
  
  .sidebar .list .item {
    position: relative;
    cursor: pointer;
    background-color:rgba(125, 68, 212, 1);
  }
  
  .sidebar .list .item a {
    color: white;
    
    font-family: 'Exo 2', 'sans-serif';
    font-size: 16px;
    text-decoration: none;
    display: block;
    padding: 5px 20px;
    line-height: 30px;
  }
  
  .sidebar .list .item a:hover {
    background-color: rgba(91, 47, 158, .4);
    transition: 0.3s ease;
  }
  
  .sidebar .list .item a .dropdown {
    position: absolute;
    right: 0;
    margin: 8px 20px;
    transition: 0.3s ease;
  }
  
  .sidebar .list .item .sub-btn {
    padding-right: 60px;
  }
  
  .sidebar .list .item .sub-menu {
    background-color: rgba(255, 255, 255, 0.2);
    display: none;
  }
  
  .sidebar .list .item .sub-menu a {
    padding-left: 35px;
  }

  .sidebar .menu .close-btn {
    position: relative;
    color: white;
    font-size: 20px;
    margin: 10px;
    cursor: pointer;
  
  }
  
  .sidebar .menu-btn {
    top: 40px;
    position: fixed;
    z-index: 1;
    margin: 10px;
    font-size: 20px;
    cursor: pointer;
  }