@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
#dashboard-title {
  font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
  text-align: center;
  margin: 36px 5px 5px 5px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #222;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #776CFA;
}
input:focus + .slider {
  box-shadow: 0 0 1px #776CFA;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.slider.round {
  border-color: black;
  border-width: 2px;
  border-style:solid;
}
.currency {
  background-color: #222;
  max-height: fit-content;
  padding: 10px;
  border-radius: 5px;
  border-color: black;
  border-width: 5px;
  border-style: inset;
  display: flex;
  font-size: 13px;
  float: center;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.currencyBox {
  text-align: center;
}

.config-title {
  font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-stretch: 1% 500%;
    text-rendering: optimizespeed;
    display: block;
}
.currency .text {
  margin: 10px 0px 10px 0px;
}
input, select {
  display: block;
  margin: 10px 0px 0px 0px;
  box-sizing: border-box;
  border: 2px solid black;
  border-radius: 4px;
  background-color: #776CFA;
  color: white;
  transition-duration: 0.4s;
}
input:focus, select:hover {
  background-color: black;
  border: 2px solid black;
  transition-duration: 0.4s;
}
.dashboard-main #button, .dashboard-main #rolebutton, .dashboard-main #welcomeMessageButton {
  max-height: 50px;
    max-width: 150px;
    font-size: 16px;
    padding: 5px;
    background-color: #4CAF50;
    color: white;
    position: relative;
    display: block;
    margin: 8px 0px 0px 0px;
    transition-duration: 0.4s;
}
.dashboard-main #button:hover, .dashboard-main #rolebutton:hover, .dashboard-main #welcomeMessageButton:hover {
  background-color: white; /* Green */
  color: #4CAF50;
  cursor: pointer;
}
.currency .confirm-save, .roleconfirm-save, .welcomeMessageConfirm-save {
  display: inline-flex;
  color: #4CAF50;
  position: relative;
  bottom: 15px;
  text-align: right;
  float: right;
  font-weight: bold;
}
.switchs {
  background-color: #222;
  max-height: fit-content;
  padding: 10px;
  border-radius: 5px;
  border-color: black;
  border-width: 5px;
  border-style: inset;
  display: inline-flex;
  font-size: 13px;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    float: center;
    justify-content: center;
}
.switchs .switch, .switchs .config-title {
  margin: 0px 10px 0px 10px;
  text-align: center;
  float: center;
}
.roleconfig, .welcomeMessageConfig {
  background-color: #222;
  max-height: fit-content;
  padding: 10px;
  border-radius: 5px;
  border-color: black;
  border-width: 5px;
  border-style: inset;
  display: block;
  font-size: 13px;
  box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.roleconfig .text, .welcomeMessageConfig .text {
  display: block;
}
.welcomeMessageInput, .welcomeMessageSelect {
  display: inline;
}
.welcomeMessageSelect {
  margin: 0px 0px 0px 5px;
}
@media all and (min-width:820px) {
  .currency, .switchs, .roleconfig, .welcomeMessageConfig {
    position: relative;
    display: flex;
    max-width: 60%;
    left: 20%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  form {
    width: 100%;
    max-height: fit-content;
  }
  .dashboard-main input, .dashboard-main select, .dashboard-main #button, .dashboard-main #rolebutton, .dashboard-main #welcomeMessageButton {
    margin: 8px auto 0px auto;
  }
  
  .login {
      padding-right: 5px;
    }
}
@media all and (max-width:820px) {
  .switchs {
    position: relative;
    width: 100%;
    text-align: center;
  }
  .currency {
    position: relative;
    width: 100%;
    text-align: center;
  }
  .welcomeMessageConfig input, .welcomeMessageConfig select {
    width: 45%;
  }
  
  .right-side-entries .username {
    visibility: hidden;
    position: fixed !important;
  }
}

  #gear{
    filter: invert(100%);
    position: relative;
    top: 2px;
    margin-right: 3px;
    cursor: default;
}
#icon {
  cursor: default;
}

#discord{
  filter: invert(100%);
}

.right-side-entries img {
border-radius: 50%;
}