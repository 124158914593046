@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Titillium+Web&display=swap');
div .commandsPage {
  background-color: #222;
  min-width: fit-content;
  min-height: fit-content;
  color: white;
  display: flex;
}

#command {
  color: #eee;
  background-color: rgb(24, 24, 24);
  width: 100%;
  transition: 0.4s;
  font-family: "Exo 2","sans-serif";
  text-transform: none;
  font-weight: 400;
}

#subcommand, #subcommandGroup {
  color: #eee;
  background-color: rgb(18, 18, 18);
  width: 100%;
  transition: 0.4s;
  font-family: "Exo 2","sans-serif";
  text-transform: none;
  font-weight: 400;
}

#subcommandInGroup {
  color: #eee;
  background-color: rgb(13, 13, 13);
  width: 100%;
  transition: 0.4s;
  font-family: "Exo 2","sans-serif";
  text-transform: none;
  font-weight: 400;
}

@media all and (min-width:820px) {
  .commandsPage #main {
      padding-left: 20%;
  }

  

  .commandsPage .close-btn {
      visibility: hidden;
  }
  
  .commandsPage .menu-btn {
      visibility: hidden;
  }
}

@media all and (max-width:820px) {
  .commandsPage .close-btn {
      visibility: visible;
  }
  
  .commandsPage .menu-btn {
      visibility: visible;
  }
}





.commandsPage .rotate {
  transform: rotate(90deg);
}


  
  .commandsPage nav.navigation-bar.fixed {
    display:flex;
    flex-direction: row;
      margin:0px;
    background-color:#9851ff;
      height:35px;
      width:100%;
      font-family:Oswald;
      font-weight:bold;
      font-size:18px;
      text-shadow: 1px 1px rgba(0,0,0,.2);
  }
  
  .commandsPage nav.fixed {
    position: fixed;
    z-index: 999;
  }
  
  .commandsPage nav.navigation-bar .left-side-entries {
      display: flex;
      flex-grow: 1;
      flex-flow: wrap;
      overflow: hidden;
  }
  
  .commandsPage nav ul {
      padding:2px 0px;
      margin:0px;
  }
  
  .commandsPage nav.navigation-bar.fixed a {
      text-decoration:none;
      color:#FFFFFF;
      padding:0px 8px 0px 8px;
  }
  
  /*nav a:hover {
      color:#5212aa;
      background-color:#FFFFFF;
  }*/
  
  .commandsPage h1, .commandsPage h2, .commandsPage h3, .commandsPage h4, .commandsPage h5 {
    text-align: center;
  }
  
  .commandsPage ul {
    max-width: fit-content;
    list-style-type: none;
  }
  .commandsPage ul li {
    border-bottom-color: rgba(0, 0, 0, 0.5);
    border-bottom-style: solid;
    border-radius: 1px;
  }
  .commandsPage #main {
    display: block;
  }
  .commandsPage #main .list {
    float: left;
    min-width: 50vw;
    justify-content: left;
    align-items: flex-start;
  }
  .commandsPage #main {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .commandsPage #gear{
        filter: invert(100%);
    }
  
    .commandsPage #discord{
        filter: invert(100%);
    }
  
    .commandsPage #twitter {
    height: 25px;
    width: 25px;
    position: relative;
    top: 5px;
  }
  
  .commandsPage #terminal{
      filter: invert(100%);
  }
  
  .commandsPage #fusion {
    padding: 0em 20em;
  }
  
  .commandsPage .left-side-entries img {
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }

  @media all and (min-width:820px) {

  .commandsPage h1, .commandsPage h2, .commandsPage h3, .commandsPage h4, .commandsPage h5 {
    font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-stretch: 1% 500%;
    text-rendering: optimizespeed;
  }

  .commandsPage table {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: rgb(30, 30, 30);
    font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-stretch: 1% 500%;
    text-rendering: optimizespeed;
    margin-left: auto;
  margin-right: auto;
    border-radius: 5px;
    width: 90%;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flexbox;
  }

  .commandsPage .commandsCategory #title {
    padding-top: 40px;
  }

  .commandsPage #title {
    font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.5px;
    font-stretch: 1% 500%;
    text-rendering: optimizespeed;
  }

  .commandsPage * {
    box-sizing: border-box;
  }

  .commandsPage td {
    padding: 3px 8px;
  }

  .commandsPage #commandName {
     min-width: fit-content !important;
     white-space: nowrap;
     min-width: 300px !important;
  }
  .commandsPage #nameText {
    margin-left: 30px;
  }
}

@media all and (max-width:820px) {
  .commandsPage h1, .commandsPage h2, .commandsPage h3, .commandsPage h4, .commandsPage h5 {
    font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-stretch: 1% 500%;
    text-rendering: optimizespeed;
  }

  .commandsPage table {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: rgb(30, 30, 30);
    font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-stretch: 1% 500%;
    text-rendering: optimizespeed;
    margin-left: auto;
  margin-right: auto;
    border-radius: 5px;
    width: 100vw;
    margin-bottom: 5px;
    justify-content: center;
    align-items: center;
    display: flexbox;
  }

  .commandsPage .commandsCategory #title {
    padding-top: 30px;
  }

  .commandsPage #title {
    font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.5px;
    font-stretch: 1% 500%;
    text-rendering: optimizespeed;
  }

  .commandsPage * {
    box-sizing: border-box;
  }

  #command {
    color: #eee;
    background-color: #222;
    width: 100%;
    transition: 0.4s;
    font-family: "Exo 2","sans-serif";
    text-transform: none;
    font-weight: 400;
  }

  .commandsPage td {
    padding: 3px 5px;
  }

  .commandsPage #commandName {
    min-width: fit-content !important;
    min-width: 150px !important;
 }

 .commandsPage #nameText {
  margin-left: 15px;
}
}

.commandsPage #commandArgs {
 min-width: fit-content !important;
}

.commandsPage #commandDescription {
 min-width: fit-content !important;
}
  
  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  #command:hover {
    background-color: rgb(51, 51, 51);
  }

.commandsPage #loading {
  position: absolute;
  display: flex;
  left: 50%;
  top: 45vh;
  z-index: 1;
  width: 60px;
  height: 60px;
  margin: -50px 0 0 -50px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #6b03ff;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  justify-content: center;
  align-items: center;
  }
 
    .commandsPage #dashboard {
      position: relative;
      top: 4px;
    }

    #invert {
      filter: invert(1);
    }

    .commandsPage .subcommands {
      display: contents;
      min-width: 520px;
    }

    .commandsPage #expandIcon {
      position:absolute;
      background-color: rgb(70, 70, 70);
      border-radius: 3px;
      border-style:solid;
      border-color: rgb(70, 70, 70);
    }

    .commandsPage #arg-description {
      padding: 10px;
      background-color: #090909;
      position: absolute;
      border-radius: 10px;
      border-style: solid;
      border-color: #090909;
      display: none;
    }

    .commandsPage #arg-text {
      background-color: rgb(20, 20, 20);
      border-radius: 5px;
      border-color: rgb(10, 10, 10);
      border-width: 2px;
      border-style:solid;
    }

    .commandsPage #arg-text:hover {
      text-decoration: underline;
      text-decoration-color: white;
    }

    .commandsPage #arg-text:hover + #arg-description {
      display: block;
    }
  
  /* Style the accordion panel. Note: hidden by default */