div .empresas {
    background-color: #222;
    min-width: fit-content;
    min-height: fit-content;
    color: white;
    display: flex;
}

.empresas .highlight {
    background-color: rgb(20, 20, 20);
    border-radius: 5px;
    border-color: rgb(10, 10, 10);
    border-width: 2px;
    border-style:solid;
}

.empresas .highlight:hover {
    text-decoration: underline;
    text-decoration-color: white;
}

.empresas .sidebar {
    position: absolute;
    background-color:#9851ff;
    min-width: fit-content;
    width: 20%;
    max-width: 250px;
    height: 100vh;
    overflow-y: auto;
    transition: 0.3s linear;
    transition-property: left;
}

@media all and (min-width:820px) {
    .empresas .text {
        padding-left: 20%;
    }

    .empresas .sidebar {
        position: fixed;
    }

    .empresas .close-btn {
        visibility: hidden;
    }
    
    .empresas .menu-btn {
        visibility: hidden;
    }
}

@media all and (max-width:820px) {
    .empresas .text {
        padding-left: 10px;
    }

    .empresas .sidebar {
        position: fixed;
        left: -28vh;
        transition: 0.3s linear;
        transition-property: left;
    }

    .empresas .close-btn {
        visibility: visible;
    }
    
    .empresas .menu-btn {
        visibility: visible;
    }
}

.empresas .sidebar.active {
    left: 0;
}

.empresas .sidebar .menu {
    width: 100%;
}

.empresas .sidebar .menu .item {
    position: relative;
    cursor: pointer;
    background-color:rgba(125, 68, 212, 1);
}

.empresas .sidebar .menu .item a {
    color: white;
    
    font-family: 'Exo 2', 'sans-serif';
    font-size: 16px;
    text-decoration: none;
    display: block;
    padding: 5px 20px;
    line-height: 30px;
}

.empresas .sidebar .menu .item a:hover {
    background-color: rgba(91, 47, 158, .4);
    transition: 0.3s ease;
}

.empresas .sidebar .menu .item a .dropdown {
    position: absolute;
    right: 0;
    margin: 8px 20px;
    transition: 0.3s ease;
}

.empresas .sidebar .menu .item .sub-btn {
    padding-right: 60px;
}

.empresas .sidebar .menu .item .sub-menu {
    background-color: rgba(255, 255, 255, 0.2);
    display: none;
}

.empresas .sidebar .menu .item .sub-menu a {
    padding-left: 35px;
}

.empresas .rotate {
    transform: rotate(90deg);
}

.empresas .close-btn {
    position: relative;
    color: white;
    font-size: 20px;
    margin: 10px;
    cursor: pointer;
}

.empresas .menu-btn {
    position: absolute;
    margin: 10px;
    font-size: 20px;
    cursor: pointer;
}

.empresas h1, .empresas h2 {
    padding: 35px 0px 10px 0px;
    text-align: center;
}

.empresas p {
    padding: 0px 5vw;
    text-align: left;
    font-size: 120%;
}

@media all and (max-width:620px) {
    .empresas p {
        padding: 0px 3vw;
        text-align: left;
        font-size: 3.49vw;
    }
}

.empresas .text ul {
    padding: 0px 5vw;
}

.empresas .tabela {
    float: center;
    justify-content: center;
    align-items: center;
    display: grid;
    text-align: left;
}

.empresas #values {
    float: center;
    justify-content: center;
    align-items: center;
    display: grid;
    text-align: left;
}
