.buy #loading {
    position: absolute;
    display: flex;
    left: 50%;
    top: 45vh;
    z-index: 1;
    width: 60px;
    height: 60px;
    margin: -50px 0 0 -50px;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #6b03ff;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    justify-content: center;
    align-items: center;
    }