@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Titillium+Web&display=swap');

nav.navigation-bar.fixed {
    display:inline-flex;
      margin:0px;
    background-color:#9851ff;
      height:35px;
      width:100%;
      font-family:Oswald;
      font-weight:bold;
      font-size:18px;
      text-shadow: 1px 1px rgba(0,0,0,.2);
  }
  
  nav.fixed {
    position: fixed;
    z-index: 999;
    top: 0px;
  }
  
  nav.navigation-bar .left-side-entries {
      display: flex;
      flex-grow: 1;
  }
  
  nav ul {
      padding:2px 0px;
      margin:0px;
  }
  
  nav.navigation-bar.fixed a {
      text-decoration:none;
      color:#FFFFFF;
      padding:4px 8px 0px 8px;
  }

  nav.right-side-entries img {
    border-radius: 50%;
  }


  .userMenu button {
    font-family: Oswald;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 100%;
    color: #fff;
  }

  .userMenu {
    text-align: right;
  }

  .userMenu .accountMenu {
    position: absolute;
    top: 40px;
    background: #000;
    border-style: solid;
    border-radius: 8px;
    border-color: #000;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    width: 100px;
    right: 0px;
  }
  
  .userMenu .accountMenu.active {
    visibility: visible;
    opacity: 1;
  }

  .userMenu .accountMenu::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: #000;
    transform: rotate(45deg);
  }

  .userMenu .accountMenu .logout-button {
    filter: invert(100%);
    border: none;
    border-radius: 0px;
    width: 20%;
  }

  .userMenu .accountMenu .logout-text {
    font-family: 'Lato', sans-serif;
    bottom: 2px;
  }
  
  .userMenu .accountMenu li {
    display: flex;
    list-style-type: none;
    font-family: 'Lato', sans-serif;
  }

  @media all and (max-width:820px) {
    nav.navigation-bar.fixed {
      font-size: 17px;
    }

    nav.navigation-bar.fixed #gear, #discord {
      height: 17px;
      width: 17px;
    }

    .userMenu button img {
        position: absolute;
        bottom: 0px;
        right: 2px;
      }
  }

  @media all and (min-width:821px) {
    .userMenu {
      text-align: right;
      position: fixed;
      top: 0px;
      right: 0px;
    }
  }