.guilds {
    display: inline-flex;
}
.guilds {
    float: left;
    text-align: left;
}
#guilds {
    display: flexbox;
    max-width: fit-content;
    margin-left: 5px;
}