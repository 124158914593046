.ProfileImagePage {
    align-items: center;
    justify-content: center;
    display: grid;
    max-width: fit-content;
    margin: auto;
    background-color: #222;
    border-radius: 10px;
    border-style: initial;
    border-color: #222;
}

.ProfileImagePage h4 {
    margin: 10px;
}

.ProfileImagePage .inputs {
    display: flex;
}

.ProfileImagePage #bannerInput, #aboutMeInput, #profileMethodInput {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: grid;
    padding: 5px 40px;
}

.ProfileImagePage button {
    max-height: 50px;
      font-size: 16px;
      padding: 5px;
      background-color: #4CAF50;
      color: white;
      position: relative;
      transition-duration: 0.4s;
  }
  .ProfileImagePage button:hover:not(.error) {
    background-color: white; /* Green */
    color: #4CAF50;
    cursor: pointer;
  }

  .ProfileImagePage .error, .error:hover {
    max-height: 50px;
      font-size: 16px;
      padding: 5px;
      background-color: #af4c4c;
      cursor: not-allowed;
      color: white;
      position: relative;
      transition-duration: 0.4s;
  }